// noinspection HtmlDeprecatedAttribute

$(() => {
    const $modal = app.DOM.content.find('#modal-help-guides');
    const $iframe_container = $modal.find('.modal__container');
    const $help_guides = app.DOM.content.find('.help-guides');

    /**
     * /admin/help_guides/all
     * /admin/help_guides/after
     * /admin/help_guides <- default
     */

    $help_guides.find('.column').on('click', e =>{

        const $el = $(e.target);

        if( $el.filter('a,i').length ) {
            return;
        }

        const $parent = $el.closest('.column');
        const $next = $parent.next();
        const data = $parent.data();

        if( app.URI[2] === 'list_after' ) {
            if( $next.filter('iframe').length ) {
                $next.remove();
            } else {
                app.DOM.content.find('iframe.scribes').remove();
                $parent.after(`<iframe class="scribes" width="1000" height="${data.height}" frameborder="0" allowfullscreen src="${data.url}"></iframe>`);
            }
        } else {
            MicroModal.show('modal-help-guides');
            $iframe_container.html(`<iframe width="1000" height="${data.height}" frameborder="0" allowfullscreen src="${data.url}"></iframe>`);
        }

    });

    $.each($help_guides, (k,v) => {
        const $filter = $(v).prev().find('.help-filter-input');
        const $columns = $(v).find('.column');

        $filter.on('keyup', e => {

            const val = e.target.value.toLowerCase();

            if( val ){
                $columns.hide();

                $.each($columns, (k,v) => {
                    let title = $(v).attr('data-title').toLowerCase();

                    if( title.indexOf(val) >= 0 ) {
                        $(v).show();
                    }
                });
            } else {
                $columns.show();
            }
        });
    });

    app.DOM.form_tab_a.on('click clickInit', e => {

        setTimeout( () => {

            app.DOM.tabs.filter(':visible').find(':input').focus();

        }, 100);

    }).eq(0).trigger('clickInit');
});